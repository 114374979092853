<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <!-- <NavbarStyleTwo /> -->
        <PageBanner pageTitle="Contact" className="page-banner-area bg-6" />
        <!-- <BreadcrumbOne subtitle='Contact' /> -->
        <Map />
        <!-- <Overview /> -->
        <Enquiry />
        
        <Footer class="margin-zero" />
    </div>
</template>

<script>
// import Topbar from '../Layouts/Topbar'
// import NavbarStyleTwo from '../Layouts/NavbarStyleTwo'
// import BreadcrumbOne from '../Layouts/breadcrumbOne'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
//import Overview from '../Common/Overview'
//import Talk from '../Common/Talk'
import Map from '../Contact/Map'
import Enquiry from '../ContactUs/Enquiry'
import Footer from '../Layouts/Footer'

export default {
    name: 'ContactPage',
    components: {
        // Topbar,
        // NavbarStyleTwo,
        // BreadcrumbOne,
        Navbar,
        PageBanner,
        // Overview,
        Enquiry,
        Map,
        Footer,
    }
}
</script>