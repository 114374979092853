<template>
    <div class="about-area pb-75">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-wrap-image" data-tilt>
                        <img src="../../assets/images/about/about-3.jpg" alt="image" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-wrap-content" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <div class="about-bg-text">ABOUT US</div>
                        <span>WHO WE ARE</span>
                        <h3>Welcome to Pavithram</h3>
                        <p align="justify">
                            Our organisation, formed in 2016, is a collective effort of the elite Brahmin community of Coimbatore to promote and uplift 
                            our community by promoting our culture, religious practices, and other community-oriented activities. Our objective is to 
                            create a strong sense of unity and togetherness among our people and to empower them in every possible way.
                        </p>
                        <div class="about-btn">
                            <router-link to="/about-us" class="default-btn">Know More About Us</router-link>
                        </div>
                    </div>
                </div>
            </div>

            <!--<div class="about-inner-box">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-md-6">
                        <div class="single-about-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                             <h3>Heading</h3> 
                            <p>Lorem ipsum dolor sit amet consetetur sadip scing elitr sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyamei erat sed diam voluptua at vero eos et accusam et justo duo.</p>
                            <p>Lorem ipsum dolor sit amet consetetur sadip scing elitr sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyamei erat sed diam voluptua at vero eos et accusam et justo duo.</p>
                            <p>Lorem ipsum dolor sit amet consetetur sadip scing elitr sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyamei erat sed diam voluptua at vero eos et accusam et justo duo.</p>
                            <p>Lorem ipsum dolor sit amet consetetur sadip scing elitr sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyamei erat sed diam voluptua at vero eos et accusam et justo duo.</p>
                            
                            
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6">
                        <div class="single-about-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <div class="card-image" data-tilt>
                                <img src="../../assets/images/about/about-4.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>-->
        </div>

        <div class="about-circle-shape">
            <img src="../../assets/images/about/about-circle.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>