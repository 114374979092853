<template>
    <div class="services-area margin-zero ptb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <div class="services-section-content" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <div class="services-bg-text">EVENTS</div>
                        <span>EVENTS</span>
                        <h3>Check <b>Pavithram</b> Events And Activities</h3>
                        <p>View the events and activities so far through a captivating visual journey, highlighting the essence and beauty of Hindu culture.</p>
                        <div class="services-section-btn">
                            <router-link to="/events" class="default-btn">
                                Explore All Events
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="services-slides">
                        <carousel
                            :autoplay="5000"
                            :wrap-around="true"
                            :settings='settings' 
                            :breakpoints='breakpoints'
                        >
                            <Slide v-for="slide in carouselItems" :key="slide.id">
                                <div class="services-item">
                                    <div class="services-image">
                                        <router-link :to="'/events/'+slide.event_id">
                                            <img :src="path+slide.event_image" alt="image">
                                        </router-link>
                                    </div>
                                    <div class="services-content">
                                        <h3>
                                            <router-link :to="'/events/'+slide.event_id">{{slide.event_title}}</router-link>
                                        </h3>
                                        <p>{{slide.event_date}}</p>
                                        <router-link :to="'/events/'+slide.event_id" class="services-btn">
                                            View More
                                        </router-link>
                                    </div>
                                </div>
                            </Slide>

                            <template #addons>
                                <Pagination />
                            </template>
                        </carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Pagination  } from 'vue3-carousel';
import axios from 'axios';
import { BasePath, instance, AdminPath } from '../../api';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent ({
    name: 'Services',
    components: {
        Carousel,
        Slide,
        Pagination,
    },
    axios,
    data: () => ({
        path : AdminPath,
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },
        carouselItems: [
            {
                id: 1,
                image: '/assets/media/auth/agency.png',
                title: 'No Data',
                desc: 'No data Found',
                link: '/',
                btnText: 'View More',
            }, 
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'center',
            },
            1024: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
            1200: {
                itemsToShow: 3,
                snapAlign: 'center',
            },
        },
    }),
    created: function() {
        this.getEvents();        
    },
    methods: {
        getEvents: function() {
            instance.get(BasePath+'get-events') 
            .then(response => { 
                this.carouselItems = response.data.events;
                this.total_events = response.data.event_count;
                // console.log(response.data.events);                
            })
            .catch(function (error) {
                console.log(error);
            });
        },       
    },
})
</script>