<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <!-- <BreadcrumbOne subtitle='Events' /> -->
        <PageBanner pageTitle="Events" className="page-banner-area bg-3" />
        <Events />
        <Footer class="margin-zero" />
    </div>
</template>

<script>
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
// import BreadcrumbOne from '../Layouts/breadcrumbOne'
import PageBanner from '../Common/PageBanner'
import Events from '../Events/Events' 
import Footer from '../Layouts/Footer'

export default {
    name: 'EventsPage',
    components: {
        // Topbar,
        Navbar,
        // BreadcrumbOne,
        PageBanner,
        Events,
        Footer,
    }
}
</script>