<template>
    <div class="profile-authentication-area">
        <div class="container pt-100">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="login-form" style="max-width: none !important;">
                        <h2>Member Registration</h2>
                        <form @submit="formSubmit" enctype="multipart/form-data">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input type="text" name="name" class="form-control border border-warning" placeholder="Name" v-model="name" maxlength="25">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Son Of</label>
                                        <input type="text" name="son_of" class="form-control border border-warning" placeholder="S/O" v-model="son_of" v-bind:class="{'form-control':true, 'is-invalid' : !validSonof(son_of)}" maxlength="25">
                                        <div class="invalid-feedback">Son Of is required</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="email" name="email" Placeholder="Email" class="form-control border border-warning" v-model="email" v-bind:class="{'form-control':true, 'is-invalid' : !validEmail(email)}">
                                        <div class="invalid-feedback">A valid email is required</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Mobile</label>
                                        <input type="text" name="mobile_no" Placeholder="Mobile" class="form-control" v-model="mobile_no" maxlength="10" style="background-color: #d3caca;border:solid 1px darkgrey;" disabled >
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Flat No</label>
                                        <input type="text" name="flat_no" Placeholder="Flat No" class="form-control border border-warning" v-model="flat_no" maxlenght="50">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Area</label>
                                        <input type="text" name="area" Placeholder="Area" class="form-control border border-warning" v-model="area" maxlength="25">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Land Mark</label>
                                        <input type="text" name="land_mark" Placeholder="Land Mark" class="form-control border border-warning" v-model="land_mark" maxlength="25">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>City</label>
                                        <input type="text" name="city" Placeholder="City" class="form-control border border-warning" v-model="city"  v-bind:class="{'form-control':true, 'is-invalid' : !validCity(city)}" maxlength="25">
                                        <div class="invalid-feedback">City is required</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>State</label>
                                        <select name="state" id="state" class="form-control form-select py-3 border border-warning" v-model="state" v-bind:class="{'form-control':true, 'is-invalid' : !validState(state)}">
                                            <option value="">Select State</option>
                                            <option v-for="state in states" v-bind:key="state.state_id" v-bind:value="state.state_id" > {{ state.state_name }} </option>
                                        </select>
                                        <div class="invalid-feedback">State is required</div>
                                        <!-- <select name="state" id="state" class="form-control form-select py-3 border border-warning">
                                            <option value="">Select State</option>
                                            <option value="tamilnadu">Tamilnadu</option>
                                            <option value="kerala">Kerala</option>
                                        </select> -->
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>District</label>
                                        <select name="district" id="district" class="form-control form-select py-3 border border-warning" v-model="district" v-bind:class="{'form-control':true, 'is-invalid' : !validDistrict(district)}">
                                            <option value="">Select District</option>
                                            <option v-for="district in districts" v-bind:key="district.district_id" v-bind:value="district.district_id"> {{ district.district_name }} </option>
                                        </select>
                                        <div class="invalid-feedback">District is required</div>
                                        <!-- <select name="district" id="district" class="form-control form-select py-3 border border-warning">
                                            <option>Select District</option>
                                            <option value="tvl">Tirunelveli</option>
                                            <option value="palakkad">Palakkad</option>
                                        </select> -->
                                    </div>
                                </div>
                                <!-- <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Country/Region</label>
                                        <input type="text" name="country" Placeholder="Country" class="form-control border border-warning" v-model="country">
                                    </div>
                                </div> -->
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Pincode</label>
                                        <input type="text" name="pincode" Placeholder="Pincode" class="form-control border border-warning" v-model="pincode" v-bind:class="{'form-control':true, 'is-invalid' : !validPincode(pincode)}" maxlength="10">
                                        <div class="invalid-feedback">Pincode is required</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Referred By</label>
                                        <input type="text" name="referred_by" id="referred_by" Placeholder="Referred By" class="form-control border border-warning" v-model="referred_by" maxlength="10">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Upload Image</label>
                                        <input type="file" name="image" id="image" class="form-control-file border border-warning" v-on:change="onFileChange"/>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group" id="preview">
                                        <img v-if="url" :src="url" />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <button type="submit" class="default-btn">Register as Member</button>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="alert alert-success" id="success_msg" style="display: none;">
                                        <strong>Success!</strong><a href="#" class="alert-link">Registered Successfully</a>.
                                    </div>
                                    <div class="alert alert-danger" id="error_msg" style="display: none;">
                                        <strong>Failed!</strong><a href="#" class="alert-link">Mobile No Already Exist!!!</a>.
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
<script>
import axios from 'axios';
import { BasePath, instance } from '../../api';   
export default {
    axios,
    data() {
            return {
                resultText: '',
                showResult: false,
                states:[],
                countries : [],
                districts : [],
                suburl:null,
                name: '',
                son_of: '',
                email: '',
                mobile_no : '',
                flat_no : '',
                area : '',
                land_mark : '',
                city : '',
                state : '',
                district : '',
                country : '',
                pincode : '',
                referred_by : '',
                output: '',
                valid : false, 
                submitted : false,
                url: null,
                image: '',
            }
        },
    created: function() {
        this.getstateList();
        this.getdistrictList();
        this.fetchMemberData();
    },
    methods: {
        getstateList: function() {
            instance.get(BasePath+'get-states') 
            .then(response => {
                this.states = response.data.states;
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        getdistrictList: function() {
            instance.get(BasePath+'get-districts') 
            .then(response => {
                this.districts = response.data.districts;
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        getcountryList: function() {
            instance.get(BasePath+'get-countries') 
            .then(response => {
                this.countries = response.data.countries;
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        fetchMemberData: function() {
            var member_name = localStorage.getItem('member_name');
            var mem_mobile_no = localStorage.getItem('mobile_no');
            this.name = member_name;
            this.mobile_no = mem_mobile_no;
        },
        validate : function(){
            // this.sonofBlured = true;
            // this.emailBlured = true;
            // this.cityBlured = true;
            // this.stateBlured = true;
            // this.districtBlured = true;
            // this.pincodeBlured = true;
            if( this.validSonof(this.son_of) && this.validEmail(this.email) && this.validCity(this.city) && this.validState(this.state) && this.validDistrict(this.district) && this.validPincode(this.pincode)){
                this.valid = true;
            }
            /*if( this.validEmail(this.email)){
                this.valid = true;
            }*/

        },
        validSonof : function(son_of) {
            if (son_of) {
                return true;
            }
        },
        validEmail : function(email) {
        var re = /(.+)@(.+){2,}\.(.+){2,}/;
            if(re.test(email.toLowerCase())){
                return true;
            }
        },
        validCity : function(city) {
            if (city) {
                return true;
            }
        },
        validState : function(state) {
            if (state) {
                return true;
            }
        },
        validDistrict : function(district) {
            if (district) {
                return true;
            }
        },
        validPincode : function(pincode) {
            if (pincode) {
                return true;
            }
        },
        onFileChange(e) {
            const file = e.target.files[0];
            this.url = URL.createObjectURL(file);
             // Store the file data
            this.image = e.target.files[0];
            /*this.photo = {
                name: file.name,
                data: file
            };*/
        },
        formSubmit: function(e){
            e.preventDefault();
                let currentObj = this;
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
 
                let formData = new FormData();
                formData.append('name', this.name);
                formData.append('son_of', this.son_of);
                formData.append('email', this.email);
                formData.append('mobile_no', this.mobile_no);
                formData.append('flat_no', this.flat_no);
                formData.append('area', this.area);
                formData.append('city', this.city);
                formData.append('land_mark', this.land_mark);
                formData.append('country', this.country);
                formData.append('pincode', this.pincode);
                formData.append('referred_by', this.referred_by);
                formData.append('state', this.pincode);
                formData.append('district', this.district);
                formData.append('image', this.image);
                
                this.validate();
                if(this.valid){
                    this.submitted = true;
                    instance.post(BasePath+'save-members', formData, config)
                    .then(function (response) {
                        currentObj.output = response.data;
                        if(response.data.response == 'success'){
                            document.getElementById('success_msg').style.display ='block';  // to display
                            setTimeout(function () 
                            { 
                                document.getElementById('success_msg').style.display ='none';  // to hide
                            }, 3000);
                            
                            e.target.reset();
                            window.location.href = '/member-welcome';
                        }else{
                            document.getElementById('error_msg').style.display ='block'; 
                            setTimeout(function () 
                            { 
                                document.getElementById('error_msg').style.display ='none';  // to hide
                            }, 3000);
                        }
                        console.log(response.data.response);
                    })
                    .catch(function (error) {
                        currentObj.output = error;
                    });
                }else{
                    this.submitted = false;
                }
        },
       
        call_to_div: function() {
            window.scroll(0, 0);
        }
    },
    head() {
        return {
            title: 'Members Registration',
        }
    },
    name: 'MemberRegistrationForm'
}
</script>