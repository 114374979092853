<template>
    <div class="about-area mt-5">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-image" data-tilt>
                        <img src="../../assets/images/about/about-2.png" alt="image" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-content" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <span>WHO WE ARE</span>
                        <h3>Welcome to <b>Pavithram</b></h3>
                        <p>Our organisation, formed in 2016, is a collective effort of the elite Brahmin community of Coimbatore to promote and uplift our community by promoting our culture, religious practices, and other community-oriented activities. Our objective is to create a strong sense of unity and togetherness among our people and to empower them in every possible way.</p>
                        <p>We believe that the promotion of our heritage and traditions will not only enrich our community, but also contribute positively to the larger society. To this end, we organise regular Satsangams, religious discourses, cultural events, and other activities that bring together the members of our community. Our organisation also recognises the achievements of our community members who have excelled in various fields and are a source of inspiration for our younger generation.</p>
                        <!-- <div class="about-btn">
                            <router-link to="/about-style-1" class="default-btn">
                                Know More About Us
                            </router-link>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
        <div class="about-shape-1">
            <img src="../../assets/images/about/about-shape.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'About'
}
</script>