<template>
    <div>
        <!-- <Topbar /> -->
        <!-- <Navbar /> -->
        <Navbar />
        <!-- <BreadcrumbOne subtitle='Member Registration' /> -->
        <PageBanner pageTitle="Member Registration" className="page-banner-area bg-5" />
        <!-- <Authentication /> -->
        <MemberRegistrationForm/>
        <!-- <Overview /> -->
        <Footer class="margin-zero" />
    </div>
</template>

<script>
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
//import BreadcrumbOne from '../Layouts/breadcrumbOne'
import PageBanner from '../Common/PageBanner'
// import Authentication from '../Authentication/Authentication'
import MemberRegistrationForm from '../Members/MemberRegistrationForm'
// import Overview from '../Common/Overview'
import Footer from '../Layouts/Footer'

export default {
    name: 'AuthenticationPage',
    components: {
        // Topbar,
        Navbar,
        //BreadcrumbOne,
        PageBanner,
        //Authentication,
        // Overview,
        MemberRegistrationForm,
        Footer,
    }
}
</script>