<template>
    <div>
        <NavbarStyleTwo />
        <BreadcrumbOne subtitle='Member Welcome Page' />
        <MemberWelcome />
        <Footer class="margin-zero" />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layouts/NavbarStyleTwo'
import BreadcrumbOne from '../Layouts/breadcrumbOne'
import Footer from '../Layouts/Footer'
import MemberWelcome from '../Members/MemberWelcome'

export default {
    name: 'MemberWelcomePage',
    components: {
    NavbarStyleTwo,
    BreadcrumbOne,
    MemberWelcome,
    Footer,
}
}
</script>