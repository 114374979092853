<template>
    <div class="projects-area pb-75">
        <div class="container">
            <div class="section-title mt-4">
                <span>PAVITHRAM ALBUMS</span>
                <h2>Our Latest <b>Incredible</b> Album's</h2>
            </div>
            <p align="justify">
                Welcome to the latest albums and photos of various events organized by Pavithram on Hindu culture, 
                including speaker meetings and Carnatic concerts. Through these events, Pavithram aims to showcase and 
                promote the rich traditions and heritage of Hindu culture to a wider audience. We invite you to browse through
                our collection of images and albums, which capture the vibrant and diverse performances and discussions held 
                during these events. Enjoy the glimpse of the beautiful moments, and we hope it inspires you to join us and explore
                and appreciate the beauty and significance of Hindu culture.
            </p>            
            <div class="d-flex justify-content-center" v-if="isLoading">
                <div class="mx-2 fw-bold mt-1">please Wait..</div>
                <div class="spinner-border text-danger" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
            <div class="row justify-content-left">
                <div 
                    class="col-lg-4 col-md-6"
                    v-for="album in albums"
                    :key="album.id"
                >
                    <div class="single-projects-item" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                        <div class="projects-image">
                            <router-link :to="album.btnLink">
                                <!-- <img :src="album.image" alt="image"> -->
                                <img :src="path + album.image" :style="{height: `${imageHeight}px`,width: `${imageWidth}px`}">
                            </router-link>
                        </div>
                        <div class="projects-content">
                            <h3>
                                <router-link :to="'/album/'+album.id">
                                    {{album.title}}
                                </router-link>
                            </h3>
                            <router-link :to="'/album/'+album.id" class="projects-btn">
                                {{album.btnText}}
                            </router-link>
                        </div>
                    </div>
                </div>
            </div> 
        </div>

        <div class="projects-bg-shape-1">
            <img src="../../assets/images/projects/projects-bg-shape.png" alt="image">
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { BasePath, instance,AdminPath } from '../../api';   
export default {
    axios,
    data() {
            return {
                albums:[],
                path : AdminPath,
                imageWidth: 416,
                imageHeight: 280,
                isLoading: true
            }
        },
    created: function() {
        this.getAlbums();
        
    },
    methods: {
        getAlbums: function() {
            instance.get(BasePath+'get-albums') 
            .then(response => {
                this.albums = response.data.albums;
                this.isLoading = false
            })
            .catch(function (error) {
                console.log(error);
                this.isLoading = false
            });
        },
       
    },
    head() {
        return {
            title: 'Albums',
        }
    },
    name: 'Albums'
}
</script>