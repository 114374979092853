<template>
    <div class="container-fluid" style="padding-top:100px !important">
        <div class="py-10 rounded border mb-2" style="background-color: #a25641 !important;">
            <nav aria-label="breadcrumb" style="padding:20px 30px;">
                <ol class="breadcrumb mb-0">
                    <li class="breadcrumb-item fw-bold" style=""><router-link to="/" class="text-white">Home</router-link></li>
                    <li class="breadcrumb-item fw-bold" style="color:white !important;" aria-current="page">{{ subtitle }}</li>
                </ol>
            </nav>
        </div>
    </div>
</template>
<script>
export default {
    name: 'BreadcrumbOne',
    props: ['subtitle'],
    data() {
        return {
            // currentYear: new Date().getFullYear(),
        };
    }
}
</script>