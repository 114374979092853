<template>
    <div class="talk-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <!-- <div class="talk-image" data-tilt>
                        <img src="../../assets/images/talk/talk-2.png" alt="image">
                        <div class="talk-circle">
                            <img src="../../assets/images/talk/talk-circle.png" alt="image">
                        </div>
                    </div> -->
                    <div class="talk-content">
                        <h1>Get in touch</h1>
                        <p class="mb-3">Looking for help? Fill the form and start a new adventure.</p>
                        <h4>Postal Address</h4>
                        <p>16,Kalidas Road,Ramnagar,</p>
                        <p>Coimbatore - 641 009.</p>
                        <p>Tamil Nadu, India.</p>
                    </div>
                    <div class="talk-content">
                        <h4>Communication Details</h4>
                        <div class="d-flex">
                            <span style="padding-bottom: 0px;margin-bottom: 0px;"><i class="ri-phone-fill fs-5"></i></span>
                            <span class="mx-2" style="color:#646464;padding-bottom: 0px;margin-bottom: 0px;">95005 85795</span>
                        </div>
                        <div class="d-flex">
                            <span  style="padding-bottom: 0px;margin-bottom: 0px;"><i class="ri-phone-fill fs-5"></i></span>
                            <span class="mx-2" style="color:#646464;padding-bottom: 0px;margin-bottom: 0px;">98422 10007</span>
                        </div>
                        <div class="d-flex">
                            <span><i class="ri-mail-fill fs-5"></i></span>
                            <span class="mx-2" style="color:#646464;">support@pavithram.org</span>
                        </div>
                        
                    </div> 
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="talk-content">
                        <span>LET'S TALK</span>
                        <h3>We Would Like To Hear From You Any Question</h3>
                        <p>Like to ask us something? Use the below enquiry form , we'll get back to you as early as possible.</p>

                        <form id="contactFormTwo" @submit="formSubmit">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <input type="text" name="name" v-model="name" class="form-control" placeholder="Your name" maxlength="25">
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" v-model="email" class="form-control" placeholder="Your email address" maxlength="30">
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="mobile_no" v-model="mobile_no" class="form-control" placeholder="Your Mobile Number" maxlength="10"  @input="acceptNumber">
                                    </div>
                                </div>
                                <!-- <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="subject" class="form-control" cols="10" rows="1" placeholder="Write your Subject..."></textarea>
                                    </div>
                                </div> -->
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" v-model="message" class="form-control" cols="30" rows="6" placeholder="How we can help?" maxlength="500"></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="default-btn">Send Message<span></span></button>
                                </div>
                                <div class="col-lg-12 col-md-12 mt-3">
                                    <div class="alert alert-success" id="success_msg" style="display: none;">
                                        <strong>Success!</strong><a href="#" class="alert-link">Message Sent Successfully</a>.
                                    </div>
                                    <div class="alert alert-danger" id="error_msg" style="display: none;">
                                        <strong>Failed!</strong><a href="#" class="alert-link">Message Not Sent!!</a>.
                                    </div>
                                    <div class="alert alert-danger" id="valid_error_msg" style="display: none;">
                                        <a href="#" class="alert-link"> Please!!Fill the Fields</a>.
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { BasePath, instance } from '../../api';   
export default {
    axios,
    data() {
        return {
            name : '',
            mobile_no : '',
            email : '',
            message : '',
        }
    },
    methods: {
        validate : function(){
            if( this.validName(this.name) && this.validEmail(this.email) && this.validMobileno(this.mobile_no) && this.validMessage(this.message)){
                this.valid = true;
            }
        },
        validName : function(name) {
            if (name) {
                return true;
            }
        },
        validEmail : function(email) {
        var re = /(.+)@(.+){2,}\.(.+){2,}/;
            if(re.test(email.toLowerCase())){
                return true;
            }
        },
        validMobileno : function(mobile_no) {
            if (mobile_no) {
                return true;
            }
        },
        validMessage : function(message) {
            if (message) {
                return true;
            }
        },
        acceptNumber(e) {
            e.target.value = e.target.value.replace(/[^0-9]+/g, '');
        },
        formSubmit: function(e){
            e.preventDefault();
            let currentObj = this;
            let formData = new FormData();
            formData.append('name', this.name);
            formData.append('email', this.email);
            formData.append('mobile_no', this.mobile_no);
            formData.append('message', this.message);
            this.validate();
            if(this.valid){
                this.submitted = true;
                instance.post(BasePath+'send-enquiry', formData)
                .then(function (response) {
                    currentObj.output = response.data;
                    if(response.data.response == 'success'){
                        document.getElementById('success_msg').style.display ='block';  // to display
                        setTimeout(function () 
                        { 
                            document.getElementById('success_msg').style.display ='none';  // to hide
                        }, 3000);
                        e.target.reset();
                    }else{
                        document.getElementById('error_msg').style.display ='block'; 
                        setTimeout(function () 
                        { 
                            document.getElementById('error_msg').style.display ='none';  // to hide
                        }, 3000);
                    }
                })
                .catch(function (error) {
                    currentObj.output = error;
                });
            }else{
                this.submitted = false;
                document.getElementById('valid_error_msg').style.display ='block'; 
                setTimeout(function () 
                { 
                    document.getElementById('valid_error_msg').style.display ='none';  // to hide
                }, 3000);
            }
        },
    },
    name: 'Enquiry',
   
}
</script>