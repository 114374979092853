<template>
    <div>
        <NavbarHome />
        <MainBanner />
        <Features />
        <About />
        <EventsOne />
        <!-- <ChooseUs /> -->
        <!-- <Video /> -->
        <!-- <Testimonials /> -->
        <!-- <Talk /> -->
        <Gallery />
        <!-- <Partner /> -->
        <!-- <Team /> -->
        <!-- <Overview /> --> 
        <!-- <Blog /> -->
        <Footer class="margin-zero" />
    </div>
</template>

<script>
import NavbarHome from '../Layouts/NavbarHome'
import MainBanner from '../HomeThree/MainBanner'
import Features from '../HomeThree/Features'
import About from '../HomeThree/About'
import EventsOne from '../HomeThree/EventsOne'
// import ChooseUs from '../HomeThree/ChooseUs'
// import Video from '../Common/Video'
// import Testimonials from '../HomeThree/Testimonials'
// import Talk from '../HomeThree/Talk'
import Gallery from '../HomeThree/Gallery'
// import Partner from '../Common/Partner'
// import Team from '../HomeThree/Team'
// import Overview from '../Common/Overview'
// import Blog from '../Common/Blog'
import Footer from '../Layouts/Footer'

export default { 
    name: 'HomePageThree',
    components: {
        NavbarHome,
        MainBanner,
        Features,
        About,
        EventsOne,
        // ChooseUs,
        // Video,
        // Testimonials,
        // Talk,
        Gallery,
        // Partner,
        // Team,
        // Overview,
        // Blog,
        Footer,
    }
}
</script>