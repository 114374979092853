<template>
    <div>
        <NavbarStyleTwo />
        <BreadcrumbOne subtitle='Edit Profile' />
        <MemberEditProfile/>
        <Footer class="margin-zero" />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layouts/NavbarStyleTwo'
import BreadcrumbOne from '../Layouts/breadcrumbOne'
import MemberEditProfile from '../Members/MemberEditProfile'
import Footer from '../Layouts/Footer'

export default {
    name: 'MemberEditProfilePage',
    components: {
        NavbarStyleTwo,
        BreadcrumbOne,
        MemberEditProfile,
        Footer,
    }
}
</script>