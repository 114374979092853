<template>
    <div class="main-banner-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-content">
                        <!--span data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">UNITED BY THE THREAD OF SERVICE</span>
                        <h1 data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">We Make The World Better, Pavithram</h1>
                        <p data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">Lorem ipsum dolor sit amet consetetur 
                            sadipscing elitr sed diam nonumy eirmod tempor.</p-->
                        <img src="../../assets/images/pavithram-logo-header-1.png" style="width:650px !important" alt="image">
                        <div class="banner-btn mr-4 mem-reg-btn" data-aos="fade-right" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                            <router-link to="/member-login" class="default-btn">Join as Member</router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-image">
                        <img src="../../assets/images/main-banner/banner-new3.png" alt="image" data-aos="fade-down" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">

                        <div class="banner-circle">
                            <img src="../../assets/images/main-banner/banner-circle.png" alt="image" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1000" data-aos-once="true">
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="banner-shape-1">
            <img src="../../assets/images/main-banner/banner-shape-1.png" alt="image">
        </div>
        <div class="banner-shape-2">
            <img src="../../assets/images/main-banner/banner-shape-2.png" alt="image">
        </div>
        <div class="banner-dot-shape-1">
            <img src="../../assets/images/main-banner/dot-1.png" alt="image">
        </div>
        <div class="banner-dot-shape-2">
            <img src="../../assets/images/main-banner/dot-2.png" alt="image">
        </div>
        <div class="banner-dot-shape-3">
            <img src="../../assets/images/main-banner/dot-3.png" alt="image">
        </div>
        <div class="banner-dot-shape-4">
            <img src="../../assets/images/main-banner/dot-4.png" alt="image">
        </div>
        <div class="banner-dot-shape-5">
            <img src="../../assets/images/main-banner/dot-5.png" alt="image">
        </div>
        <!-- <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div> -->
    </div>
</template>



<script>
export default {
    name: 'MainBanner'
}
</script>