<template>
    <div class="projects-area style-two-area ptb-100">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <div class="projects-section-content" data-aos="fade-down" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                        <div class="projects-bg-text">GALLERY</div>
                        <span>GALLERY</span>
                        <h3>Our Latest photos from  recent albums !!!</h3>
                        <p align="justify">
                            Welcome to the latest albums and photos of various events organized by Pavithram on Hindu culture, including speaker 
                            meetings and Carnatic concerts. Through these events, Pavithram aims to showcase and promote the rich traditions and heritage of 
                            Hindu culture to a wider audience. We invite you to browse through our collection of images and albums, which capture the vibrant and 
                            diverse performances and discussions held during these events. Enjoy the glimpse of the beautiful moments, and we hope it inspires you 
                            to join us and explore and appreciate the beauty and significance of Hindu culture.
                        </p>
                        <div class="projects-section-btn">
                            <router-link to="/albums" class="default-btn">
                                Explore All
                            </router-link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 col-md-12">
                    <div class="projects-slides-two">
                        <carousel
                            :autoplay="5000"
                            :wrap-around="true"
                            :settings='settings' 
                            :breakpoints='breakpoints'
                        >
                            <Slide v-for="slide in carouselItems" :key="slide.id" >
                                <div class="projects-item bg-F2F1F3">
                                    <div class="projects-image">
                                        <router-link :to="'/album/'+slide.id">
                                            <img :src="path+slide.image" alt="image">
                                        </router-link>
                                    </div>
                                    <div class="projects-content">
                                        <h3>
                                            <router-link :to="'/album/'+slide.id">
                                                {{slide.title}}
                                            </router-link>
                                        </h3>
                                        <router-link :to="'/album/'+slide.id" class="projects-btn">
                                            {{slide.btnText}}
                                        </router-link> 
                                    </div>
                                </div>
                            </Slide>
                            
                            <template #addons>
                                <Navigation />
                            </template>
                        </carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue';
import { Carousel, Slide, Navigation  } from 'vue3-carousel';
import axios from 'axios';
import { BasePath, instance,AdminPath } from '../../api'; 

import 'vue3-carousel/dist/carousel.css';
export default defineComponent ({
    name: 'Projects',
    components: {
        Carousel,
        Slide,
        Navigation,
    },
    axios,
    data: () => ({
        path : AdminPath,
        settings: {
            itemsToShow: 1,
            snapAlign: 'center',
        },        
        carouselItems: [
            {
                id: 1,
                image: '/assets/media/auth/agency.png',
                title: 'No data',
                btnLink: '/',
                btnText: 'View More',
            },
        ],
        breakpoints: {
            0: {
                itemsToShow: 1,
                snapAlign: 'center',
			},
            768: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
            1024: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
            1200: {
                itemsToShow: 2,
                snapAlign: 'left',
            },
        },
    }),    
    created: function() {
        this.getAlbums();        
    },
    methods: {
        getAlbums: function() {
            instance.get(BasePath+'get-albums') 
            .then(response => {
                this.carouselItems = response.data.albums;
            })
            .catch(function (error) {
                console.log(error);
            });
        },       
    },
})
</script>