<template>
    <div class="features-area pb-75">
        <div class="container">
            <div class="features-inner-box">
                <div class="row justify-content-center">
                    <div class="col-lg-4 col-md-6" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1000" data-aos-once="true">
                        <div class="single-features-card" data-tilt>
                            <div class="features-image" data-tilt>
                                <router-link to="/member-login">
                                    <img src="../../assets/images/features/features-1.png" alt="image">
                                </router-link>
                            </div>
                            <div class="content">
                                <h3>
                                    <router-link to="/member-login">Members</router-link>
                                </h3>
                                <p>Join us and become a member of Pavithram, and be a part of preserving the rich cultural heritage of Hinduism.</p>
                            </div>
                            <div class="hover-content">
                                <h3>
                                    <router-link to="/member-login">Members</router-link>
                                </h3>
                                <router-link to="/member-login" class="features-btn">View More</router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once="true">
                        <div class="single-features-card" data-tilt>
                            <div class="features-image" data-tilt>
                                <router-link to="/events">
                                    <img src="../../assets/images/features/features-2.png" alt="image">
                                </router-link>
                            </div>
                            <div class="content">
                                <h3>
                                    <router-link to="/events">Events & Activities</router-link>
                                </h3>
                                <p>View the events and activities so far through a captivating visual journey, highlighting the essence and beauty of Hindu culture.</p>
                            </div>
                            <div class="hover-content">
                                <h3>
                                    <router-link to="/events">Events & Activities</router-link>
                                </h3>
                                <router-link to="/events" class="features-btn">View More</router-link>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6" data-aos="fade-down" data-aos-delay="100" data-aos-duration="1000" data-aos-once="true">
                        <div class="single-features-card" data-tilt>
                            <div class="features-image" data-tilt>
                                <router-link to="/albums">
                                    <img src="../../assets/images/features/features-3.png" alt="image">
                                </router-link>
                            </div>
                            <div class="content">
                                <h3>
                                    <router-link to="/albums">Gallery</router-link>
                                </h3>
                                <p>Browse through our collection of images and albums to witness the vibrancy and diversity of our events and activities.</p>
                            </div>
                            <div class="hover-content">
                                <h3>
                                    <router-link to="/albums">Gallery</router-link>
                                </h3>
                                <router-link to="/albums" class="features-btn">View More</router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Features'
}
</script>