<template>
    <div class="about-area pt-100 pb-75">
        <div class="container">
            <div class="about-inner-box">
                <div class="row justify-content-center">
                    <div class="col-lg-8 col-md-6">
                        <div class="single-about-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <!-- <h3>Our Vision</h3> -->
                            <p>We acknowledge and appreciate the support of other organisations and individuals who share our vision and work towards the betterment of our community. Our organisation actively collaborates with such entities in various initiatives and activities that promote our common goal.</p>
                            <p>We are committed to the welfare and progress of our community and believe that our collective efforts will make a positive impact in the lives of our members and the community at large.</p>
                        </div>
                    </div>

                    <!-- <div class="col-lg-4 col-md-6">
                        <div class="single-about-card" data-aos="fade-down" data-aos-delay="70" data-aos-duration="700" data-aos-once="true">
                            <h3>Our Mission</h3>
                            <p>Lorem ipsum dolor sit amet consetetur sadip scing elitr sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyamei erat sed diam voluptua at vero eos et accusam et justo duo.</p>
                        </div>
                    </div> -->
                    <div class="col-lg-4 col-md-6">
                        <div class="single-about-card" data-aos="fade-up" data-aos-delay="80" data-aos-duration="800" data-aos-once="true">
                            <div class="card-image" data-tilt>
                                <img src="../../assets/images/about/about-4.jpg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AboutTwo'
}
</script>