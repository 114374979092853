<template>
    <!-- <Topbar /> -->
    <Navbar />
    <!-- <BreadcrumbOne subtitle='Event Detail' /> -->
    <PageBanner pageTitle="Event Details" className="page-banner-area bg-3" />
    <div class="container">
        <div class="events-details-image">                
            <div class="courses overview">
                <div class="events-details-header">
                    <h2 style="margin-top: 1rem;border-bottom: 4px solid #FEB302;display: inline-block;padding-bottom: 5px;">{{event_title}}</h2>
                    <ul class="events-list border-0 px-0 py-1">
                        <li><i class="ri-calendar-line"></i>{{event_date}}</li>
                        <li><i class="ri-map-pin-line"></i>{{event_venue}}</li>
                        <!-- <li><i class="ri-time-line"></i>12.00PM</li> -->
                    </ul>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">         
                        <div class="events-details-location desk">
                            <img :src="path + event_cover_image" alt="image" style="width:856px;height:514px" >
                        </div>
                        <div class="events-details-location div-only-mobile" v-bind:style="{ 'background-image': 'url(' + path + event_cover_image + ')',width:'100% !important',height:'100vh !important', 'align-items': 'center !important', display: 'flex !important','flex-direction': 'column !important','justify-content': 'center !important','background-repeat': 'no-repeat !important','background-size':'100% 100% !important'}"></div>
                        <div class="courses-details-desc">
                            <div class="courses overview">
                                <div v-html="event_details">  
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="events-details-info border-0 px-3 py-0">
                            <h6>Event Photos</h6>
                            <!-- <div class="row g-4">
                                <div class="col-md-6" v-for="item in event_images" :key="item">
                                    <div class="user-review">
                                        <img :src="path + item" alt="image" class="rounded" :style="{height: `${imageHeight}px`,width: `${imageWidth}px`}">
                                    </div>
                                </div>                  
                            </div> -->
                            <lightgallery
                                :settings="{ speed: 500, plugins: plugins}"
                                :onInit="onInit"
                                :onBeforeSlide="onBeforeSlide"
                                class="row"
                            >
                                <a
                                    v-for="item in items"
                                    :key="item.id"
                                    className="gallery-item"
                                    :data-src="path+item.src"
                                    :class="item.class"
                                >
                                    <div class="single-gallery-item">
                                        <img className="img-responsive" :style="{height: `${imageHeight}px`,width: `${imageWidth}px`}" :src="path + item.thumb" />
                                    </div>
                                </a>
                            </lightgallery>
                        </div>
                        <!-- <div class="events-details-info border-0 px-3 py-5">
                            <h6>Other Events</h6>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div> 
    <Footer class="margin-zero" />
</template>
<style>

@media only screen and (max-width:767px)
{
    .desk {
        display: none !important;
    }
    .div-only-mobile {
        display: block !important;
    }
}
@media screen and (max-width: 600px) {
    .desk {
        display: none !important;
    }
    .div-only-mobile {
        display: block !important;
        
    }
}
@media only screen  and (min-width : 1224px) {
   .div-only-mobile {
        display: none !important;
    }
    .desk {
        display: block !important;
    }
}

@media only screen  and (min-width : 1824px) {
   .div-only-mobile {
        display: none !important;
    }
    .desk {
        display: block !important;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    .desk {
        display: none !important;
    }
   .div-only-mobile {
        display: block !important;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    .desk {
        display: none !important;
    }
   .div-only-mobile {
        display: block !important;
    }
}


@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    .desk {
        display: none !important;
    }
   .div-only-mobile {
        display: block !important;
    }
}

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    .desk {
        display: none !important;
    }
   .div-only-mobile {
        display: block !important;
    }
} 

@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    .desk {
        display: none !important;
    }
   .div-only-mobile {
        display: block !important;
    }
}
</style>
<script>
import Navbar from '../../../Layouts/Navbar'
// import BreadcrumbOne from '../../../Layouts/breadcrumbOne'
import PageBanner from '../../../Common/PageBanner'
import Lightgallery from 'lightgallery/vue';
import lgZoom from 'lightgallery/plugins/zoom';
import Footer from '../../../Layouts/Footer'
import axios from 'axios';
import { BasePath, instance,AdminPath } from '../../../../api'; 
let lightGallery= null;
export default {
    name: 'EventsDetails',
    components: {
        Lightgallery,
        // BreadcrumbOne,
        PageBanner,
        Navbar,
        Footer,
        // EventsDetails
        //lightgallery,
    },
    watch: {
        items() {
            this.$nextTick(() => {
                lightGallery.refresh();
            });
        },
    },
    axios,
    data() {
        return {
            plugins: [lgZoom],
            days: '',
            hours: '',
            minutes: '',
            seconds: '',
            path : AdminPath,
            items:[],
            event_date: '',
            event_title: '',
            event_venue: '',
            meta_desc: '',
            event_details : '',
            event_cover_image : '',
            event_images : [],
            imageWidth: 200,
            imageHeight: 130,
        }
    },
    created() { // Turn data into viewable values        
        this.getEventDetails(); 
    },
    methods: {
        getEventDetails: function() {
            var obj             = new Object();
            obj["event_id"]   = this.$route.params.type;
            instance.get(BasePath+'get-event-details/'+this.$route.params.type) 
            .then(response => {
                this.items = response.data.events.event_images;
                this.event_venue = response.data.events.event_venue;
                this.event_date = response.data.events.event_date;
                this.event_title = response.data.events.event_title;
                this.event_details = response.data.events.event_details;
                this.meta_desc = response.data.events.meta_description;
                this.event_images = response.data.events.src;
                this.event_cover_image = response.data.events.cover_image;
                // console.log(response.data.events.event_venue);
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        onInit: (detail) => {
            lightGallery = detail.instance;
        },
        onBeforeSlide: () => {
            // console.log('calling before slide');
        },
    }
}
</script>