<template>
    <div>
        <div class="career-details-area pt-100 pb-100">
            <div class="container">
                <div class="row">
                    <div class="col-lg-9 col-md-12">
                        <div class="career-details-desc">
                            <h2 style="margin-top: 1rem; border-bottom: 4px solid rgb(254, 179, 2); display: inline-block; padding-bottom: 5px;">My Profile</h2>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="career-content">
                                        <h4>Personal Details:</h4>
                                        <div class="mb-2" style="">
                                            <span class='circle' style="margin-top: 2px !important;font-size: 12px;color: #FEB302;"><i class="ri-checkbox-blank-circle-fill"></i></span>
                                            <span class="label">
                                                <span class="fs-6 text-dark fw-bolder mx-2">Name :</span>
                                                <span class="fs-6 mx-1">{{ details.name }}</span>
                                            </span>
                                        </div>
                                        <div class="mb-2" style="">
                                            <span class='circle' style="margin-top: 2px !important;font-size: 12px;color: #FEB302;"><i class="ri-checkbox-blank-circle-fill"></i></span>
                                            <span class="label">
                                                <span class="fs-6 text-dark fw-bolder mx-2">S/O :</span>
                                                <span class="fs-6 mx-1">{{ details.son_of }}</span>
                                            </span>
                                        </div>
                                        <div class="mb-2" style="">
                                            <span class='circle' style="margin-top: 2px !important;font-size: 12px;color: #FEB302;"><i class="ri-checkbox-blank-circle-fill"></i></span>
                                            <span class="label">
                                                <span class="fs-6 text-dark fw-bolder mx-2">Referred By :</span>
                                                <span class="fs-6 mx-1">{{ details.refered_by }}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="career-content">
                                        <h4>Contact Details:</h4>
                                        <div class="mb-2" style="">
                                            <span class='circle' style="margin-top: 2px !important;font-size: 12px;color: #FEB302;"><i class="ri-checkbox-blank-circle-fill"></i></span>
                                            <span class="label">
                                                <span class="fs-6 text-dark fw-bolder mx-2">Email:</span>
                                                <span class="fs-6 mx-1">{{details.email}}</span>
                                            </span>
                                        </div>
                                        <div>
                                            <span class='circle' style="margin-top: 2px !important;font-size: 12px;color: #FEB302;"><i class="ri-checkbox-blank-circle-fill"></i></span>
                                            <span class="label">
                                                <span class="fs-6 text-dark fw-bolder mx-2">Mobile Number:</span>
                                                <span class="fs-6 mx-1">{{details.mobile_no}}</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            
                           
                            <div class="col-lg-12 col-md-12 col-xs-12" style="margin-top: 1rem; border-bottom: 2px solid #d3d0c8; display: inline-block; padding-bottom: 5px;">&emsp;</div>
                            <div class="career-content">
                                <h4>Address Details:</h4>
                                <div class="mb-2" style="">
                                    <span class='circle' style="margin-top: 2px !important;font-size: 12px;color: #FEB302;"><i class="ri-checkbox-blank-circle-fill"></i></span>
                                    <span class="label">
                                        <span class="fs-6 text-dark fw-bolder mx-2">Flat No :</span>
                                        <span class="fs-6 mx-1">{{ details.flat_no }}</span>
                                    </span>
                                </div>
                                <div class="mb-2" style="">
                                    <span class='circle' style="margin-top: 2px !important;font-size: 12px;color: #FEB302;"><i class="ri-checkbox-blank-circle-fill"></i></span>
                                    <span class="label">
                                        <span class="fs-6 text-dark fw-bolder mx-2">Area :</span>
                                        <span class="fs-6 mx-1">{{ details.area }}</span>
                                    </span>
                                </div>
                                <div class="mb-2" style="">
                                    <span class='circle' style="margin-top: 2px !important;font-size: 12px;color: #FEB302;"><i class="ri-checkbox-blank-circle-fill"></i></span>
                                    <span class="label">
                                        <span class="fs-6 text-dark fw-bolder mx-2">Landmark :</span>
                                        <span class="fs-6 mx-1">{{ details.land_mark }}</span>
                                    </span>
                                </div>
                                <div class="mb-2" style="">
                                    <span class='circle' style="margin-top: 2px !important;font-size: 12px;color: #FEB302;"><i class="ri-checkbox-blank-circle-fill"></i></span>
                                    <span class="label">
                                        <span class="fs-6 text-dark fw-bolder mx-2">city :</span>
                                        <span class="fs-6 mx-1">{{ details.city }}</span>
                                    </span>
                                </div>
                                <div class="mb-2" style="">
                                    <span class='circle' style="margin-top: 2px !important;font-size: 12px;color: #FEB302;"><i class="ri-checkbox-blank-circle-fill"></i></span>
                                    <span class="label">
                                        <span class="fs-6 text-dark fw-bolder mx-2">District :</span>
                                        <span class="fs-6 mx-1">{{ district }}</span>
                                    </span>
                                </div>
                                <div class="mb-2" style="">
                                    <span class='circle' style="margin-top: 2px !important;font-size: 12px;color: #FEB302;"><i class="ri-checkbox-blank-circle-fill"></i></span>
                                    <span class="label">
                                        <span class="fs-6 text-dark fw-bolder mx-2">State :</span>
                                        <span class="fs-6 mx-1">{{ state }}</span>
                                    </span>
                                </div>
                                <div class="mb-2" style="">
                                    <span class='circle' style="margin-top: 2px !important;font-size: 12px;color: #FEB302;"><i class="ri-checkbox-blank-circle-fill"></i></span>
                                    <span class="label">
                                        <span class="fs-6 text-dark fw-bolder mx-2">Pincode :</span>
                                        <span class="fs-6 mx-1">{{ details.pincode }}</span>
                                    </span>
                                </div>
                            </div>
                            <!-- <div class="career-details-image">
                                <img src="../../assets/images/career-details.jpg" alt="image">

                                <a 
                                    href="javascript:void(0)" 
                                    v-on:click="isPopupMethod(isPopup)" 
                                    class="video-btn popup-youtube"
                                >
                                    <i class="ri-play-fill"></i>
                                </a>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12">
                        <div class="">
                            <!-- <img src="../../assets/images/career-details.jpg" alt="image"> -->
                            <div class="widget-area">
                                <div class="widget widget_insight">
                                    <h3 class="widget-title">Popular Link</h3>
                                    
                                    <ul class="list">
                                        <li><router-link to="/member-my-profile">My Profile</router-link></li>
                                        <li><router-link to="/member-edit-profile">Edit Profile</router-link></li>
                                        <!-- <li><router-link to="/">Family</router-link></li> -->
                                        <li><router-link to="/" @click="clearLocalStorage">Logout</router-link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div 
            class="popup-video" 
            v-if="isPopup"
        >
            <div class="d-table">
                <div class="d-table-cell">
                    <div 
                        class="popup-overlay-close"
                        v-on:click="isPopupMethod(isPopup)"
                    >
                        <div class="popup-overlay-close-line"></div>
                        <div class="popup-overlay-close-line"></div>
                    </div>
                    <div class="play-video">
                        <iframe src="https://www.youtube.com/embed/bk7McNUjWgw"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
@media screen and (max-width: 600px) {
    span .fs-6 {font-size:14px !important;}
    span .circle{font-size:5px !important;}
}

</style>
<script>
import axios from 'axios';
import { BasePath, instance,AdminPath } from '../../api';  
export default {
    name: 'MemberMyProfile',
    axios,
    data (){
        return {
            isPopup: false,
            path:AdminPath,
            details : [],
            state : '',
            district : '',
        }
    },
    created(){
        this.getMemberData();
    },
    methods: {
        isPopupMethod(isPopup){
            return this.isPopup = !isPopup
        },
        
        getMemberData: function() {
            var member_id = localStorage.getItem('user_id');
            instance.post(BasePath+'get-auth-member-data', {
                member_id: member_id,
            })
            .then(response => {
                this.details = response.data.member_data;
                this.state = response.data.state;
                this.district = response.data.district;
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        clearLocalStorage: function() {
            localStorage.removeItem('user_id');
        },
    },
}
</script>