<template>
    <Navbar />
    <!-- <BreadcrumbOne subtitle='Albums' /> -->
    <PageBanner pageTitle="Gallery" className="page-banner-area bg-4" />
    <div class="gallery-area mt-5 pb-75">
        <div class="d-flex justify-content-center" v-if="isLoading">
            <div class="mx-2 fw-bold mt-1">please Wait..</div>
            <div class="spinner-border text-danger" role="status">
                <span class="sr-only"></span>
            </div>
        </div>
        <div class="container">
            <div class="text-center py-4 fw-bold"><h3 style="margin-top: 1rem;border-bottom: 4px solid #FEB302;display: inline-block;padding-bottom: 5px;">{{title}}</h3></div>
            <lightgallery
                :settings="{ speed: 500, plugins: plugins}"
                :onInit="onInit"
                :onBeforeSlide="onBeforeSlide"
                class="row"
            >
                <a
                    v-for="item in items"
                    :key="item.id"
                    className="gallery-item"
                    :data-src="path+item.src"
                    :class="item.class"
                >
                    <div class="single-gallery-item">
                        <img className="img-responsive" :src="path + item.thumb" />
                    </div>
                </a>
            </lightgallery>
        </div>
    </div>
    <Footer class="margin-zero" />
</template>

<script>
import axios from 'axios';
// import BreadcrumbOne from '../../../Layouts/breadcrumbOne'
import PageBanner from '../../../Common/PageBanner'
import { BasePath, instance,AdminPath } from '../../../../api'; 
import Navbar from '../../../Layouts/Navbar';
import Footer from '../../../Layouts/Footer';
import Lightgallery from 'lightgallery/vue';
import lgZoom from 'lightgallery/plugins/zoom';
let lightGallery= null;

export default {
    name: 'GalleryTwoColumn',
    components: {
        Lightgallery,
        Navbar,
        // BreadcrumbOne,
        PageBanner,
        Footer
    },
    watch: {
        items() {
            this.$nextTick(() => {
                lightGallery.refresh();
            });
        },
    },
    created: function() {
        this.getAlbumImages();
    },
    mounted () {
        axios
    },
    data: () => ({
        plugins: [lgZoom],
        path : AdminPath,
        items: [],
        title: '',
        isLoading: true
    }),
    methods: {        
        onInit: (detail) => {
            lightGallery = detail.instance;
        },
        onBeforeSlide: () => {
            // console.log('calling before slide');
        },
        getAlbumImages: function() {
            var obj             = new Object();
            obj["album_id"]   = this.$route.params.type;
            instance.get(BasePath+'get-album-images/'+this.$route.params.type) 
            .then(response => {
                this.items = response.data.images;
                this.title = response.data.title; 
                this.isLoading = false
            })
            .catch(function (error) {
                console.log(error);
                this.isLoading = false
            });
            //lightGallery.refresh();
        },
    },
};
</script>
<style lang="css">
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.1.0-beta.1/css/lg-zoom.css');

</style>
