<template>
    <div>
        <NavbarStyleTwo />
        <BreadcrumbOne subtitle='My Profile' />
        <MemberMyProfile /> 
        <Footer class="margin-zero" />
    </div>
</template>

<script>
import NavbarStyleTwo from '../Layouts/NavbarStyleTwo'
import BreadcrumbOne from '../Layouts/breadcrumbOne'
import Footer from '../Layouts/Footer'
import MemberMyProfile from '../Members/MemberMyProfile'

export default {
    name: 'MemberMyProfilePage',
    components: {
    NavbarStyleTwo,
    BreadcrumbOne,
    MemberMyProfile,
    Footer
}
}
</script>