<template>
    <div class="profile-authentication-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="login-form">
                        <h2>Login</h2>
                        <form>
                            <div class="form-group">
                                <label>Mobile No</label>
                                <input type="text" class="form-control" id="mobile_no" v-model="mobile_no" maxlength="10" @input="filterNonNumeric">
                            </div>
                            <div class="form-group" id="otp_field" style="display: none;">
                                <label>OTP</label>
                                <input type="text" class="form-control border border-secondary" id="login_otp_field" v-model="otp" maxlength="6" @input="filterNonNumeric" disabled>
                            </div>
                            <div class="alert alert-success" id="otp_send_msg" style="display: none;">
                                <strong>Success!</strong><a href="#" class="alert-link"></a>.
                            </div>
                            <button type="button" class="default-btn" id="proceed_btn" @click="getOtp()">Proceed</button>
                            <button type="button" class="default-btn" id="send_otp_btn" @click="sendOtp()" style="display:none;">
                                <div class="d-flex justify-content-center" v-if="loading">
                                    <div class="spinner-border text-white" role="status">
                                        <span class="sr-only"></span>
                                    </div>
                                </div>
                                Send OTP
                            </button>
                            <button type="button" class="default-btn" id="login_btn" @click="verifyOtp()" style="display:none;">Verify & Login</button>
                            <!-- <button type="submit" class="default-btn login_btn" style="display:none;">Login</button> -->
                            <br><br>
                            <div class="alert alert-success" id="success_msg" style="display: none;">
                                <strong>Success!</strong><a href="#" class="alert-link"></a>.
                            </div>
                            <div class="alert alert-danger" id="error_msg" style="display: none;">
                                <strong>Failed!</strong><a href="#" class="alert-link"></a>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="register-form">
                        <h2>Register as Member</h2>
                        <form>
                            <div class="form-group">
                                <label>Name</label>
                                <input type="text" class="form-control" id="member_name" v-model="name" maxlength="25">
                            </div>
                            <div class="form-group">
                                <label>Mobile No</label>
                                <input type="text" class="form-control" id="reg_mobile_no" v-model="reg_mobile_no" maxlength="10"  @input="filterNonNumeric">
                            </div>
                            <div class="form-group" id="reg_otp" style="display: none;">
                                <label>OTP</label>
                                <input type="text" class="form-control border border-secondary" v-model="reg_otp" id="reg_otp_field" maxlength="6" @input="filterNonNumeric" disabled/>
                            </div>
                            <div class="alert alert-success" id="reg_otp_send_msg" style="display: none;">
                                <strong>Success!</strong><a href="#" class="alert-link"></a>.
                            </div>
                            <!-- One-time password (OTP) has been sent to your registered email address. Enter to complete your registration. -->
                            <p class="description">An OTP (one time password) will send to mobile number for verification.</p>
                            <button type="button" class="default-btn" id="reg_proceed_btn" @click="registerMember()">Proceed</button>
                            <button type="button" class="default-btn" id="reg_send_otp_btn" style="display:none;" @click="sendRegisterOtp()">Send OTP</button>
                            <button type="button" class="default-btn" id="reg_confirm_btn" style="display:none;" @click="verifyRegisterOtp()">Verify & Confirm</button>
                            <br><br>
                            <br><br>
                            <div class="alert alert-success" id="reg_success_msg" style="display: none;">
                                <strong>Success!</strong><a href="#" class="alert-link"></a>.
                            </div>
                            <div class="alert alert-danger" id="reg_error_msg" style="display: none;">
                                <strong>Failed!</strong><a href="#" class="alert-link"></a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { BasePath, instance,AdminPath } from '../../api';  
export default {
    axios,
    data() {
            return {
                resultText: '',
                showResult: false,
                path:AdminPath,
                mobile_no : '',
                otp: '',
                output: '',
                loading: false,
                name:'',
                reg_mobile_no : '',
                reg_otp : ''
            }
        },
    created: function() {
        // this.getstateList();
        // this.getdistrictList();
        //this.getOtp();
    },
    methods: {
        /*getstateList: function() {
            instance.get(BasePath+'get-states') 
            .then(response => {
                this.states = response.data.states;
            })
            .catch(function (error) {
                console.log(error);
            });
        },*/
        getOtp: function(){
                var flag = true;
                var error_msg = "";
                var mobile_no = document.getElementById("mobile_no").value;
                if(!mobile_no){
                    flag = false;
                    error_msg += "Please Enter Mobile Number!!";
                }
                if(flag){
                    document.getElementById('error_msg').style.display ='none';
                    let currentObj = this;
                    instance.post(BasePath+'save-login-otp', {
                        mobile_no: this.mobile_no,
                    })
                    .then(function (response) {
                        currentObj.output = response.data;
                        if(response.data.response == 'not_valid'){
                            document.getElementById('error_msg').style.display ='block';  // to display
                            document.getElementById('error_msg').innerHTML  ='Your account is not active or not registred.<br> Kindly register as new member and try logging again.<br>If already registred but not active, Please Contact Site Admin.';
                            /*setTimeout(function () 
                            { 
                                document.getElementById('error_msg').style.display ='none';  // to hide
                                document.getElementById('error_msg').innerHTML ='Sorry we could not find your account! Please Register and try logging in again.';  // to hide
                            }, 4000);*/
                        }else{
                            document.getElementById('proceed_btn').style.display ='none';  // to hide
                            document.getElementById('send_otp_btn').style.display ='block';
                            document.getElementById('otp_field').style.display ='block';
                        }
                    
                        console.log(response.data.response);
                    })
                    .catch(function (error) {
                        currentObj.output = error;
                    });
                }else{
                    //error msg
                    document.getElementById('error_msg').style.display ='block';  // to display
                    document.getElementById('error_msg').innerHTML = error_msg;
                    document.getElementById('mobile_no').className+='  border border-danger';
                }
                
                
        },
        sendOtp: function(){
                let currentObj = this;
                instance.post(BasePath+'send-login-otp', {
                    mobile_no: this.mobile_no,
                })
                .then(function (response) {
                    currentObj.output = response.data;
                   var mobile_no = response.data.mobile_no;
                    if(response.data.response == 'no_not_valid'){
                        document.getElementById('error_msg').style.display ='block';  // to display
                        document.getElementById('error_msg').innerHTML  ='Your account is not active or not registred.<br> Kindly register as new member and try logging again.<br>If already registred but not active, Please Contact Site Admin.';
                        setTimeout(function () 
                        { 
                            document.getElementById('error_msg').style.display ='none';  // to hide
                            document.getElementById('error_msg').innerHTML ='Your account is not active or not registred.<br> Kindly register as new member and try logging again.<br>If already registred but not active, Please Contact Site Admin.';  // to hide
                        }, 4000);
                    }else{
                        document.getElementById('proceed_btn').style.display ='none';  // to hide
                        document.getElementById('otp_field').style.display ='block';
                        document.getElementById('login_btn').style.display ='block';
                        document.getElementById('send_otp_btn').style.display ='none'; 
                        document.getElementById("login_otp_field").disabled = false;
                        document.getElementById('login_otp_field').className+='  border border-success';
                        document.getElementById('otp_send_msg').style.display ='block';  // to display
                        document.getElementById('otp_send_msg').innerHTML  ="OTP sent to your registered Mobile Number "+"(" + mobile_no + ")";
                        this.loading = true;
                    }
                    console.log(response.data.response);
                })
                .catch(function (error) {
                    currentObj.output = error;
                });
                
        },
        verifyOtp: function(){
                var flag = true;
                var error_msg = "";
                var login_otp = document.getElementById("login_otp_field").value;
                if(!login_otp){
                    flag = false;
                    error_msg += "Please Enter OTP!!";
                }
                if(flag){
                    document.getElementById('error_msg').style.display ='none';
                    let currentObj = this;
                    instance.post(BasePath+'verify-login-otp', {
                        mobile_no: this.mobile_no,
                        otp: this.otp,
                    })
                    .then(function (response) {
                        currentObj.output = response.data;
                        if(response.data.response == 'verified'){
                            document.getElementById('success_msg').style.display ='block';  // to display
                            document.getElementById('success_msg').innerHTML  ='OTP Verified Successfully';
                            var member_id = response.data.member_id;
                            localStorage.setItem('user_id',member_id);
                            setTimeout(function () 
                            { 
                                document.getElementById('success_msg').style.display ='none';  // to hide
                                document.getElementById('success_msg').innerHTML  ='OTP Verified Successfully';
                            }, 4000);
                            window.location.href = '/member-my-profile';
                        }else{
                            document.getElementById('error_msg').style.display ='block';  // to display
                            document.getElementById('error_msg').innerHTML  ='OTP Not Valid!!!Please Enter Valid OTP!!';
                            setTimeout(function () 
                            { 
                                document.getElementById('error_msg').style.display ='none';  // to hide
                                document.getElementById('error_msg').innerHTML ='OTP Not Valid!!!Please Enter Valid OTP!!';  // to hide
                            }, 4000);
                        }
                        console.log(response.data.response);
                    })
                    .catch(function (error) {
                        currentObj.output = error;
                    });
                }else{
                    document.getElementById('error_msg').style.display ='block';  // to display
                    document.getElementById('error_msg').innerHTML  = error_msg;
                }  
        },
        registerMember: function(){
                var flag = true;
                var error_msg = "";
                var add_border = "";
                var mem_name = document.getElementById("member_name").value;
                var mem_mobile_no = document.getElementById("reg_mobile_no").value;
                if(!mem_name){
                    flag = false;
                    error_msg += "Please Enter Name";
                    add_border += " form-control border border-danger";
                    document.getElementById('member_name').className+= add_border;
                }else{
                    document.getElementById('member_name').classList.remove('border','border-danger');
                }
                if(!mem_mobile_no){
                    flag = false;
                    if(error_msg){
                        error_msg += " , ";
                    }
                    
                    error_msg += "Please Enter Mobile Number";
                    add_border += " form-control border border-danger";
                    document.getElementById('reg_mobile_no').className+= add_border;
                }else{
                    document.getElementById('reg_mobile_no').classList.remove('border','border-danger');
                }
                
                if(flag){
                    document.getElementById('reg_error_msg').style.display ='none'; 
                    let currentObj = this;
                    var member_name = this.name;
                    var mobile_no = this.reg_mobile_no;
                    localStorage.setItem('member_name',member_name);
                    localStorage.setItem('mobile_no',mobile_no);
                    instance.post(BasePath+'register-member', {
                        name: this.name,
                        reg_mobile_no: this.reg_mobile_no,
                    })
                    .then(function (response) {
                        currentObj.output = response.data;
                        if(response.data.response == 'registered'){
                            document.getElementById('reg_proceed_btn').style.display ='none';  // to hide
                            document.getElementById('reg_send_otp_btn').style.display ='block';  // to show
                            document.getElementById('reg_otp').style.display ='block';  // to show
                        }else{
                            document.getElementById('reg_error_msg').style.display ='block';  // to display
                            document.getElementById('reg_error_msg').innerHTML  ='Mobile Number already Registered, Kindly use login section.';
                            setTimeout(function () 
                            { 
                                document.getElementById('reg_error_msg').style.display ='none';  // to hide
                                document.getElementById('reg_error_msg').innerHTML ='Mobile Number already Registered, Kindly use login section.';  // to hide
                            }, 40000);
                        }
                        console.log(response.data.response);
                    })
                    .catch(function (error) {
                        currentObj.output = error;
                    });
                }
                else{
                    document.getElementById('reg_error_msg').style.display ='block';  // to display
                    document.getElementById('reg_error_msg').innerHTML  = error_msg;
                   
                   
                }
                
        },
        sendRegisterOtp: function(){
                let currentObj = this;
                instance.post(BasePath+'send-register-otp', {
                    reg_mobile_no: this.reg_mobile_no,
                })
                .then(function (response) {
                    currentObj.output = response.data;
                    var mobile_no = response.data.mobile_no;
                    if(response.data.response == 'reg_no_not_valid'){
                        document.getElementById('reg_error_msg').style.display ='block';  // to display
                        document.getElementById('reg_error_msg').innerHTML  ='Sorry we could not find your account! Please Register and try login in again.';
                        setTimeout(function () 
                        { 
                            document.getElementById('reg_error_msg').style.display ='none';  // to hide
                            document.getElementById('reg_error_msg').innerHTML ='Sorry we could not find your account! Please Register and try login in again.';  // to hide
                        }, 4000);
                    }else{
                        document.getElementById('reg_proceed_btn').style.display ='none';  // to hide
                        document.getElementById('reg_confirm_btn').style.display ='block';
                        document.getElementById('reg_send_otp_btn').style.display ='none'; 
                        document.getElementById("reg_otp_field").disabled = false;
                        document.getElementById('reg_otp_field').className+='  border border-success';
                        document.getElementById('reg_otp_send_msg').style.display ='block';  // to display
                        document.getElementById('reg_otp_send_msg').innerHTML  ="OTP sent to your registered Mobile Number "+"(" + mobile_no + ")";
                        this.loading = true;
                    }
                    console.log(response.data.response);
                })
                .catch(function (error) {
                    currentObj.output = error;
                });
                
        },
        verifyRegisterOtp: function(){
                var flag = true;
                var error_msg = "";
                var mem_reg_otp = document.getElementById("reg_otp_field").value;
                if(!mem_reg_otp){
                    flag = false;
                    error_msg += "Please Enter OTP!!";
                }
                if(flag){
                    document.getElementById('reg_error_msg').style.display ='none';
                    let currentObj = this;
                    instance.post(BasePath+'verify-register-otp', {
                        reg_mobile_no: this.reg_mobile_no,
                        reg_otp: this.reg_otp,
                    })
                    .then(function (response) {
                        currentObj.output = response.data;
                        if(response.data.response == 'verified'){
                            
                            document.getElementById('reg_success_msg').style.display ='block';  // to display
                            document.getElementById('reg_success_msg').innerHTML  ='OTP Verified Successfully';
                            setTimeout(function () 
                            { 
                                document.getElementById('reg_success_msg').style.display ='none';  // to hide
                                document.getElementById('reg_success_msg').innerHTML  ='OTP Verified Successfully';
                            }, 4000);
                            window.location.href = '/members-registration';
                        }else{
                            document.getElementById('reg_error_msg').style.display ='block';  // to display
                            document.getElementById('reg_error_msg').innerHTML  ='OTP Not Valid!!!Please Enter Valid OTP!!';
                            setTimeout(function () 
                            { 
                                document.getElementById('reg_error_msg').style.display ='none';  // to hide
                                document.getElementById('reg_error_msg').innerHTML ='OTP Not Valid!!!Please Enter Valid OTP!!';  // to hide
                            }, 4000);
                        }
                        console.log(response.data.response);
                    })
                    .catch(function (error) {
                        currentObj.output = error;
                    });
                }else{
                    document.getElementById('reg_error_msg').style.display ='block';
                    document.getElementById('reg_error_msg').innerHTML = error_msg;
                }  
        },
        filterNonNumeric() {
            // Replace non-numeric characters with an empty string
            this.mobile_no = this.mobile_no.replace(/[^0-9]/g, "");
            this.reg_mobile_no = this.reg_mobile_no.replace(/[^0-9]/g, "");
            this.otp = this.otp.replace(/[^0-9]/g, "");
            this.reg_otp = this.reg_otp.replace(/[^0-9]/g, "");
        },
        call_to_div: function() {
            window.scroll(0, 0);
        }
    },
    head() {
        return {
            title: 'Member Login',
        }
    },
    name: 'MemberLoginForm'
}
</script>