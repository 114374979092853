<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <!-- <BreadcrumbOne subtitle='Gallery' /> -->
        <PageBanner pageTitle="Albums" className="page-banner-area bg-4" />
        <Albums  />
        <!-- <Talk /> -->
        <!-- <Overview /> -->
        <Footer class="margin-zero" />
    </div>
</template>

<script>
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
// import BreadcrumbOne from '../Layouts/breadcrumbOne'
import PageBanner from '../Common/PageBanner'
import Albums from '../ImageGallery/Albums'
// import Talk from '../Common/Talk'
//import Overview from '../Common/Overview'
import Footer from '../Layouts/Footer'

export default {
    name: 'ProjectsPage',
    components: {
        // Topbar,
        Navbar,
        // BreadcrumbOne,
        PageBanner,
        Albums,
        // Talk,
        //Overview,
        Footer
    }
}
</script>