<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <PageBanner pageTitle="Disclaimer Policy" className="page-banner-area bg-3" />
        <DisclaimerPolicy />
        <!-- <Overview /> -->
        <Footer class="margin-zero" />
    </div>
</template>

<script>
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import DisclaimerPolicy from '../DisclaimerPolicy/DisclaimerPolicy'
// import Overview from '../Common/Overview'
import Footer from '../Layouts/Footer'



export default {
    name: 'TermsServicePage',
    components: {
    // Topbar,
    Navbar,
    PageBanner,
    DisclaimerPolicy,
    // Overview,
    Footer,
    
}
}
</script>