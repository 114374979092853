<template>
    <div>
        <Navbar />
        <!-- <BreadcrumbOne subtitle='Member Login' /> -->
        <PageBanner pageTitle="Member Login" className="page-banner-area bg-5" />
        <MemberLoginForm />
        <Footer class="margin-zero" />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
// import BreadcrumbOne from '../Layouts/breadcrumbOne'
import PageBanner from '../Common/PageBanner'
import Footer from '../Layouts/Footer'
import MemberLoginForm from '../Members/MemberLoginForm'

export default {
    name: 'AuthenticationPage',
    components: {
    Navbar,
    //BreadcrumbOne,
    PageBanner,
    Footer,
    MemberLoginForm
}
}
</script>