<template>
    <div>
        <!-- <Topbar /> -->
        <Navbar />
        <PageBanner pageTitle="Privacy Policy" className="page-banner-area bg-5" />
        <Privacypolicy />
        <!-- <Overview /> -->
        <Footer class="margin-zero" />
    </div>
</template>

<script>
// import Topbar from '../Layouts/Topbar'
import Navbar from '../Layouts/Navbar'
import PageBanner from '../Common/PageBanner'
import Privacypolicy from '../PrivacyPolicy/PrivacyPolicy'
// import Overview from '../Common/Overview'
import Footer from '../Layouts/Footer'

export default {
    name: 'PrivacyPolicyPage',
    components: {
        // Topbar,
        Navbar,
        PageBanner,
        Privacypolicy,
        // Overview,
        Footer,
    }
}
</script>