<template>
    <div class="events-area pb-100">
        <div class="container">
            <div class="koze-grid-sorting row align-items-center mt-4">
                <div class="col-lg-6 col-md-6 result-count">
                    <p>We found <span class="count">{{ total_events }}</span> events available for you</p>
                </div>

                <div class="col-lg-6 col-md-6 ordering">
                    <div class="select-box">
                        <label>Event Type:</label>
                        <select id="event_filter" name="event_filter" @Change="getEvents()">
                            <option value="All" selected>All</option>
                            <option value="Happening">Happening</option>
                            <option value="Upcoming">Upcoming</option>
                            <option value="Completed">Completed</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-center" v-if="isLoading">
                <div class="mx-2 fw-bold mt-1">please Wait..</div>
                <div class="spinner-border text-danger" role="status">
                    <span class="sr-only"></span>
                </div>
            </div>
            <div class="row justify-content-left">
                <div class="col-lg-4 col-md-6" v-for="item in events" :key="item.event_title">
                    <div class="single-events-box" data-aos="fade-up" data-aos-delay="50" data-aos-duration="500" data-aos-once="true">
                        <div class="image">
                            <router-link :to="'/events/'+item.event_id">
                                <!-- <img src="../../assets/images/events/events-1.jpg" alt="image"> -->
                                <img :src="path + item.event_image" :style="{height: `${imageHeight}px`,width: `${imageWidth}px`}" alt="image">
                            </router-link>
                            <span class="date">{{item.event_date }}</span>
                        </div>

                        <div class="content">
                            <h3>
                                <router-link :to="'/events/'+item.event_id">
                                    {{item.event_title}}
                                </router-link>
                            </h3>
                            <span class="location"><i class="ri-map-pin-line"></i>{{item.event_venue}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import axios from 'axios';
import { BasePath, instance,AdminPath } from '../../api';   
export default {
    axios,
    data() {
            return {
                events:[],
                path : AdminPath,
                imageWidth: 416,
                imageHeight: 280,
                total_events : '',
                isLoading: true
                
            }
        },
    created: function() {
        this.getEvents();        
    },
    methods: {
        getEvents: function() {
            var filter = "";
            if(document.getElementById("event_filter")){
                filter = document.getElementById("event_filter").value;
            }else{
                filter = "All";
            }
            instance.get(BasePath+'get-events?filter='+filter) 
            .then(response => {
                this.events = response.data.events;
               this.total_events = response.data.event_count;
                // console.log(response.data.events);
                this.isLoading = false
                
            })
            .catch(function (error) {
                console.log(error);
                this.isLoading = false
            });
        },
       
    },
    head() {
        return {
            title: 'Events',
        }
    },
    name: 'Events'
}
</script>