<template>
    <div class="cart-area ptb-100">
        <div class="container">
            <div class="">
                    <div class="alert alert-success">
                        <strong>Success!</strong><a href="#" class="alert-link"></a>
                        You have successfully completed membership registration process.
                        Our backend admin team will validate your provided information and approve your registration. 
                        Once approved, You will get email notification.
                    </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
// import { BasePath, instance,AdminPath } from '../../api';   
export default {
    axios,
    head() {
        return {
            title: 'Member Welcome Page',
        }
    },
    name: 'MemberWelcome'
}
</script>

<style scoped>
    .list-item {
    display: inline-block;
    margin-right: 10px;
    }
    .list-enter-active, .list-leave-active {
    transition: all 1s;
    }
    .list-enter, .list-leave-to {
    opacity: 0;
    transform: translateY(30px);
    }
</style>