<template>
    <div class="profile-authentication-area">
        <div class="container">
            <div class="row" style="padding-top: 70px;">
                <div class="col-lg-8 col-md-12">
                    <div class="login-form" style="max-width: none !important;">
                        <h2>Edit Profile</h2>
                        <form @submit="UpdateMemberData">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input type="text" name="name" class="form-control border border-warning" placeholder="Name" v-model="name" >
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Son Of</label>
                                        <input type="text" name="son_of" class="form-control border border-warning" placeholder="S/O" v-model="son_of">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="email" name="email" Placeholder="Email" class="form-control border border-warning" v-model="email">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Mobile <span class="" style="margin-top: 2px !important;font-size: 20px;color: #0dcaf0;">
                                            <i class="ri-information-line" data-toggle="tooltip" data-placement="top" title="If you want to change your Mobile Number,Please Contact Site Admin!!"></i>
                                        </span></label>
                                        <input type="text" name="mobile_no" Placeholder="Mobile" class="form-control" v-model="mobile_no" maxlength="10" style="background-color: #d3caca;border:solid 1px darkgrey;" disabled>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Flat No</label>
                                        <input type="text" name="flat_no" Placeholder="Flat No" class="form-control border border-warning" v-model="flat_no">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Area</label>
                                        <input type="text" name="area" Placeholder="Area" class="form-control border border-warning" v-model="area">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Land Mark</label>
                                        <input type="text" name="land_mark" Placeholder="Land Mark" class="form-control border border-warning" v-model="land_mark">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>City</label>
                                        <input type="text" name="city" Placeholder="City" class="form-control border border-warning" v-model="city">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>State</label>
                                        <select name="state" id="state" class="form-control form-select py-3 border border-warning" v-model="state">
                                            <option value="">Select State</option>
                                            <option v-for="state in states" v-bind:key="state.state_id" v-bind:value="state.state_id" > {{ state.state_name }} </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>District</label>
                                        <select name="district" id="district" class="form-control form-select py-3 border border-warning" v-model="district">
                                            <option value="">Select District</option>
                                            <option v-for="district in districts" v-bind:key="district.district_id" v-bind:value="district.district_id" > {{ district.district_name }} </option>
                                        </select>
                                    </div>
                                </div>
                                <!-- <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Country/Region</label>
                                        <input type="text" name="country" Placeholder="Country" class="form-control border border-warning" v-model="country">
                                    </div>
                                </div> -->
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Pincode</label>
                                        <input type="text" name="pincode" Placeholder="Pincode" class="form-control border border-warning" v-model="pincode" maxlength="10">
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>Referred By</label>
                                        <input type="text" name="referred_by" id="referred_by" Placeholder="Referred By" class="form-control border border-warning" v-model="referred_by" maxlength="10">
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-3">
                                    <button type="submit" class="default-btn">Update</button>
                                </div>
                                <div class="col-md-6 mt-4">
                                    <div class="alert alert-success" id="success_msg" style="display: none;">
                                        <strong>Success!</strong><a href="#" class="alert-link">Profile Updated Successfully</a>.
                                    </div>
                                    <div class="alert alert-danger" id="error_msg" style="display: none;">
                                        <strong>Failed!</strong><a href="#" class="alert-link">Mobile No Already Exist!!!</a>.
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-4 col-md-12">
                    <div class="">
                        <div class="widget-area">
                            <div class="widget widget_insight">
                                <h3 class="widget-title">Popular Link</h3>
                                
                                <ul class="list">
                                    <li><router-link to="/member-my-profile">My Profile</router-link></li>
                                    <li><router-link to="/member-edit-profile">Edit Profile</router-link></li>
                                    <!-- <li><router-link to="/">Family</router-link></li> -->
                                    <li><router-link to="/" @click="clearLocalStorage">Logout</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


import axios from 'axios';
import { BasePath, instance } from '../../api';   
//import { Tooltip } from '../../assets/css/bootstrap.min.css'  // my add line
export default {
    axios,
    data() {
            return {
                resultText: '',
                showResult: false,
                states:[],
                countries : [],
                districts : [],
                suburl:null,
                name: '',
                son_of: '',
                email: '',
                mobile_no : '',
                flat_no : '',
                area : '',
                land_mark : '',
                city : '',
                state : '',
                district : '',
                country : '',
                pincode : '',
                referred_by : '',
                output: '',
            }
        },
    created: function() {
        this.getstateList();
        this.getdistrictList();
        this.getMemberData();
    },
    methods: {
        getstateList: function() {
            instance.get(BasePath+'get-states') 
            .then(response => {
                this.states = response.data.states;
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        getdistrictList: function() {
            instance.get(BasePath+'get-districts') 
            .then(response => {
                this.districts = response.data.districts;
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        getcountryList: function() {
            instance.get(BasePath+'get-countries') 
            .then(response => {
                this.countries = response.data.countries;
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        getMemberData: function() {
            var member_id = localStorage.getItem('user_id');
            instance.post(BasePath+'get-auth-member-data', {
                member_id: member_id,
            })
            .then(response => {
                this.name = response.data.member_data.name;
                this.son_of = response.data.member_data.son_of;
                this.email = response.data.member_data.email;
                this.mobile_no = response.data.member_data.mobile_no;
                this.flat_no = response.data.member_data.flat_no;
                this.area = response.data.member_data.area;
                this.city = response.data.member_data.city;
                this.land_mark = response.data.member_data.land_mark;
                this.pincode = response.data.member_data.pincode;
                this.referred_by = response.data.member_data.refered_by;
                this.state = response.data.member_data.state;
                this.district = response.data.member_data.district;
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        UpdateMemberData: function(e){
            e.preventDefault();
                let currentObj = this;
                var member_id = localStorage.getItem('user_id');
                instance.post(BasePath+'update-auth-member-data', {
                    member_id : member_id,
                    name: this.name,
                    son_of: this.son_of,
                    email : this.email,
                    mobile_no : this.mobile_no,
                    flat_no : this.flat_no,
                    area : this.area,
                    city : this.city,
                    land_mark : this.land_mark,
                    pincode : this.pincode,
                    referred_by : this.referred_by,
                    state: this.state,
                    district : this.district,
                })
                .then(function (response) {
                    currentObj.output = response.data;
                    if(response.data.response == 'success'){
                        document.getElementById('success_msg').style.display ='block';  // to display
                        setTimeout(function () 
                        { 
                            document.getElementById('success_msg').style.display ='none';  // to hide
                        }, 3000);
                        
                        //e.target.reset();
                        //window.location.href = '/member-welcome';
                    }else{
                        document.getElementById('error_msg').style.display ='block'; 
                        setTimeout(function () 
                        { 
                            document.getElementById('error_msg').style.display ='none';  // to hide
                        }, 3000);
                    }
                    console.log(response.data.response);
                })
                .catch(function (error) {
                    currentObj.output = error;
                });
                
        },
        clearLocalStorage: function() {
            localStorage.removeItem('user_id');
        },
        call_to_div: function() {
            window.scroll(0, 0);
        }
    },
    head() {
        return {
            title: 'Members Edit Profile',
        }
    },
    name: 'MemberEditProfile'
}
</script>
<style>
  @import '../../assets/css/bootstrap.min.css';
</style>